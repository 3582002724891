
import { Vue, Component } from 'vue-property-decorator';

import EditPrivateProfile from '@/components/EditPrivateProfile.vue';

@Component({
  name: "Account",
  components: {
    EditPrivateProfile,
  }
})
export default class extends Vue {
}
